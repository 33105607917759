import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './app/App';
import 'atropos/css'; // सुनिश्चित करें कि यह CSS फाइल सही पथ पर है

// Error Boundary कम्पोनेंट
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // अगले रेंडर में फॉल्बैक UI दिखाया जाएगा
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // एरर लॉगिंग के लिए कस्टम लॉजिक जोड़ सकते हैं
    console.error("Error caught in error boundary:", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // फॉल्बैक UI
      return <h1>कुछ गलत हो गया। कृपया फिर से प्रयास करें।</h1>;
    }

    return this.props.children;
  }
}

// React.StrictMode के साथ BrowserRouter और App को रेंडर करें
ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
);
