import axios from "axios";
import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
const $ = require("jquery");
$.Datatable = require("datatables.net");

const SucWithdraw = () => {
    const [user, setUser] = useState([]);
    const backendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
    const backendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
    const nodeMode = process.env.NODE_ENV;

    const baseUrl = nodeMode === "development" ? backendLocalApiUrl : backendLiveApiUrl;

    let [limit, setLimit] = useState(10);
    const [pageNumber, setPageNumber] = useState(0);
    const [numberOfPages, setNumberOfPages] = useState(0);
    const [searchList, setSearchList] = useState("");
    const [searchType, setSearchType] = useState("");
    const [findByStatus, setFindByStatus] = useState("");

    const setpageLimit = (event) => {
        setLimit(event.target.value);
    };

    const handlePageClick = async (data) => {
        setPageNumber(data.selected + 1);
    };

    const searchHandler = (event) => {
        setSearchList(event.target.value);
    };

    const searchByStatus = (event) => {
        setFindByStatus(event.target.value);
    };

    const profle = () => {
        const access_token = localStorage.getItem("token");
        const headers = {
            Authorization: `Bearer ${access_token}`
        };
        axios.get(`${baseUrl}txn/withdraw/all?page=${pageNumber}&_limit=${limit}&_q=${searchList}&_stype=${searchType}&_status=${findByStatus}`, { headers })
            .then((res) => {
                setUser(res.data.data);
                setNumberOfPages(res.data.totalPages);
            })
            .catch((error) => {
                console.error("There was an error fetching the data!", error);
            });
    };

    const universalCheckPayout = (payment_gateway, txn_id, referenceId) => {
        if (!referenceId) {
            alert('Payout txn id not found');
            return;
        }
        if (payment_gateway === 'razorpay') {
            checkrazorpaypayout(txn_id, referenceId);
        } else if (payment_gateway === 'decentro') {
            checkpayout(txn_id, referenceId);
        } else {
            alert('Nothing found');
        }
    };

    const checkpayout = (txn_id, referenceId) => {
        const access_token = localStorage.getItem("token");
        const headers = {
            Authorization: `Bearer ${access_token}`
        };
        axios.post(`${baseUrl}decentropayout/response`, { txn_id, referenceId }, { headers })
            .then((res) => {
                const icon = res.data.status === "SUCCESS" ? "success" : "danger";
                const title = res.data.status === "SUCCESS" ? "Withdraw successfully" : "Transaction Processing or Failed";

                profle();
                Swal.fire({
                    title: title,
                    icon: icon,
                    confirmButtonText: "OK",
                });
            })
            .catch((e) => {
                if (e.response.status === 401) {
                    localStorage.removeItem('token');
                    window.location.reload();
                }
            });
    };

    const checkrazorpaypayout = (txn_id, referenceId) => {
        const access_token = localStorage.getItem("token");
        const headers = {
            Authorization: `Bearer ${access_token}`
        };
        axios.post(`${baseUrl}razorpaypayoutscheck/response`, { txn_id, referenceId }, { headers })
            .then((res) => {
                const icon = res.data.status === "SUCCESS" ? "success" : "danger";
                const title = res.data.status === "SUCCESS" ? "Withdraw successfully" : "Transaction Processing or Failed";

                profle();
                Swal.fire({
                    title: title,
                    icon: icon,
                    confirmButtonText: "OK",
                });
            })
            .catch((e) => {
                if (e.response.status === 401) {
                    localStorage.removeItem('token');
                    window.location.reload();
                }
            });
    };

    const dateFormat = (e) => {
        const date = new Date(e);
        return date.toLocaleString('default', { month: 'long', day: 'numeric', hour: 'numeric', hour12: true, minute: 'numeric' });
    };

    useEffect(() => {
        profle();
    }, [pageNumber, limit, searchList, searchType, findByStatus]);

    if (!user) {
        return null;
    }

    return (
        <>
            <div className="row">
                <div className="col-12 grid-margin">
                    <div className="card">
                        <div className="card-body text-light" style={{ backgroundColor: "rgba(0, 27, 11, 0.734)" }}>
                            <h4 className="card-title">Withdrawal History</h4>
                            <select className='form-control col-sm-1 m-1 bg-dark text-light' id='pagelimit' name='pagelimit' onChange={setpageLimit}>
                                <option value="10">Set limit</option>
                                <option value="20">20</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                                <option value="500">500</option>
                            </select>

                            <div className='row'>
                                <select className='form-control col-sm-3 m-2' id='searchType' name='searchtype' onChange={(e) => setSearchType(e.target.value)}>
                                    <option value="0">Select Search by</option>
                                    <option value="Name">Name</option>
                                    <option value="Phone">Phone</option>
                                </select>
                                <input type='text' placeholder='Search...' className='form-control col-sm-4 m-2' onChange={searchHandler} />
                                <h5>Or</h5>
                                <select className='form-control col-sm-3 m-2' id='findByStatus' name='findByStatus' onChange={searchByStatus}>
                                    <option value="0">Search Status</option>
                                    <option value="SUCCESS">Success</option>
                                    <option value="Pending">Pending</option>
                                    <option value="pending">pending</option>
                                    <option value="none">Processing</option>
                                    <option value="reject">Reject</option>
                                    <option value="FAILED">Failed</option>
                                </select>
                            </div>

                            <div className="table-responsive">
                                <table className="table text-light">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>ID</th>
                                            <th>Phone</th>
                                            <th>User</th>
                                            <th>Type</th>
                                            <th>Paytm Number</th>
                                            <th>Amount</th>
                                            <th>Status</th>
                                            <th>Bank Details</th>
                                            <th>Time</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {user && user.map((data, key) => {
                                            return (
                                                <tr key={key}>
                                                    <td>{key + 1}</td>
                                                    <td>{data._id}</td>
                                                    <td>{data.User_id ? data.User_id.Phone : ''}</td>
                                                    <td>{data.User_id && <Link className="btn btn-sm btn-outline-info" to={`/user/view_user/${data.User_id._id}`}>{data.User_id.Name}</Link>}</td>
                                                    <td>{data.Withdraw_type}</td>
                                                    <td>{data.User_id.upi_id}</td>
                                                    <td>{data.amount}</td>
                                                    <td className='font-weight-bold text-success'>{(data.status === "none") ? 'Processing' : data.status}</td>
                                                    <td>{data.Withdraw_type === "Bank" && data.User_id ? `${data.User_id.bank_account} - ${data.User_id.bank_ifsc}` : 'N/A'}</td>
                                                    <td>{dateFormat(data.createdAt).split(',')[0]}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>

                            <div className='mt-4'>
                                <ReactPaginate
                                    previousLabel={"Previous"}
                                    nextLabel={"Next"}
                                    breakLabel={"..."}
                                    pageCount={numberOfPages}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={3}
                                    onPageChange={handlePageClick}
                                    containerClassName={"pagination justify-content-center"}
                                    pageClassName={"page-item"}
                                    pageLinkClassName={"page-link"}
                                    previousClassName={"page-item"}
                                    previousLinkClassName={"page-link"}
                                    nextClassName={"page-item"}
                                    nextLinkClassName={"page-link"}
                                    breakClassName={"page-item"}
                                    breakLinkClassName={"page-link"}
                                    activeClassName={"active"}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SucWithdraw;
