import axios from "axios";
import React, { useEffect, useState } from "react";
import { Card, Button, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import * as XLSX from "xlsx";
import DatePicker from "react-multi-date-picker";
import { DateObject } from "react-multi-date-picker";
import jsPDF from "jspdf";
import "jspdf-autotable";
import $ from "jquery";
import "datatables.net";

const SucWithdraw = () => {
  const [user, setUser] = useState();

  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  let baseUrl;
  if (nodeMode === "development") {
    baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }

  // Use for pagination
  let [limit, setLimit] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [values, setValues] = useState([
    new DateObject().subtract(4, "days"),
    new DateObject().add(4, "days"),
  ]);

  const setpageLimit = (event) => {
    setLimit(event.target.value);
  };

  const handlePageClick = async (data) => {
    setPageNumber(data.selected + 1);
  };

  const profle = () => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .get(
        `${baseUrl}txn/withdrawalreports/all?page=${pageNumber}&_limit=${limit}&FROM_DATE=${values[0]}&TO_DATE=${values[1]}`,
        { headers }
      )
      .then((res) => {
        setUser(res.data.datefind);
        setNumberOfPages(res.data.totalPages);
      });
  };

  const dateFormat = (e) => {
    const date = new Date(e);
    return date.toLocaleString("default", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    });
  };

  useEffect(() => {
    profle();
  }, [pageNumber, limit, values]);

  if (user === undefined) {
    return null;
  }

  const createDownloadData = () => {
    handleExport();
  };

  const handleExport = () => {
    let table1 = [
      {
        A: "Id",
        B: "UserName",
        C: "PhoneNumber",
        D: "Withdrawal Amount",
        E: "Status",
        F: "Upi Id",
        G: "Action by",
      },
    ];

    user.forEach((row) => {
      table1.push({
        A: row._id,
        B: row.User_id ? row.User_id.Name : "",
        C: row.User_id ? row.User_id.Phone : "",
        D: row.amount ? row.amount : "",
        E: row.status ? row.status : "",
        F: row.User_id.upi_id,
        G: row.action_by ? row.action_by.Name : "N/A",
      });
    });

    const ws = XLSX.utils.json_to_sheet(table1, {
      skipHeader: true,
    });
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
    XLSX.writeFile(wb, "WithdrawalReport.xlsx");
  };

  const generateInvoice = (data) => {
    const doc = new jsPDF();

    doc.setFontSize(16);
    doc.text("Invoice", 20, 20);

    doc.setFontSize(12);
    doc.text("KANIKA PLAYHUB PRIVATE LIMITED", 20, 30);
    doc.text("111 BLOCK D1 GALI NO 3, BADARPUR SOUTH,", 20, 40);
    doc.text("New Delhi, South Delhi - 110044, Delhi", 20, 50);
    doc.text("Website: kanikaplayhub.com", 20, 60);
    doc.text(`Invoice Number: ${data._id}`, 20, 70);
    doc.text(`Invoice Date: ${dateFormat(data.createdAt).split(",")[0]}`, 20, 80);

    doc.text(`Bill To:`, 20, 100);
    doc.text(`${data.User_id ? data.User_id.Name : ""}`, 20, 110);
    doc.text(`${data.User_id ? data.User_id.Phone : ""}`, 20, 120);

    doc.autoTable({
      startY: 140,
      head: [['Product Description', 'Unit Price', 'Qty', 'Total']],
      body: [
        ['Withdrawal', `Rs. ${data.amount}`, '1', `Rs. ${data.amount}`],
      ],
    });

    const finalY = doc.lastAutoTable.finalY + 10;
    doc.text(`Subtotal: Rs. ${data.amount}`, 20, finalY);
    doc.text(`Tax (0%): Rs. 0.00`, 20, finalY + 10);
    doc.text(`Total: Rs. ${data.amount}`, 20, finalY + 20);

    doc.text("User Bank Details:", 20, finalY + 40);
    doc.text(`Account holder name: ${data.User_id ? data.User_id.holder_name : ""}`, 20, finalY + 50);
    doc.text(`IFSC code: ${data.User_id ? data.User_id.ifsc_code : ""}`, 20, finalY + 60);
    doc.text(`Account number: ${data.User_id ? data.User_id.account_number : ""}`, 20, finalY + 70);
    doc.text(`UPI ID: ${data.User_id ? data.User_id.upi_id : ""}`, 20, finalY + 80);

    doc.text("Terms & Conditions:", 20, finalY + 100);
    doc.text("Please make the payment by the due date. Thank you for your business.", 20, finalY + 110);

    doc.save(`Invoice_${data._id}.pdf`);
  };

  return (
    <>
      <div className="row">
        <div className="col-12 grid-margin">
          <div className="card">
            <div className="card-body" style={{ backgroundColor: "#1f1f1f", color: "#fff" }}>
              <h4 className="card-title">Withdrawal Reports</h4>
              <Button onClick={createDownloadData} className="btn btn-primary">
                Export Data
              </Button>
              <DatePicker value={values} onChange={setValues} range />
              <select
                className="form-control col-sm-1 m-1 bg-dark text-light"
                id="pagelimit"
                name="pagelimit"
                onChange={setpageLimit}
              >
                <option value="10">Set limit</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="500">500</option>
              </select>

              <div className="table-responsive">
                <Table className="table text-light" bordered hover>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>ID</th>
                      <th>Phone</th>
                      <th>User</th>
                      <th>Type</th>
                      <th>Paytm Number</th>
                      <th>Amount</th>
                      <th>Status</th>
                      <th>Time</th>
                      <th>Action by</th>
                      <th>Invoice</th>
                      <th>Admin View</th>
                    </tr>
                  </thead>

                  <tbody>
                    {user &&
                      user.map((data, key) => (
                        <tr key={key}>
                          <td>{key + 1}</td>
                          <td>{data._id}</td>
                          <td>{data.User_id ? data.User_id.Phone : ""}</td>
                          <td>
                            {data.User_id && (
                              <Link
                                className="btn btn-sm btn-outline-info"
                                to={`/user/view_user/${data.User_id._id}`}
                              >
                                {data.User_id.Name}
                              </Link>
                            )}
                          </td>
                          <td>{data.Withdraw_type}</td>
                          <td>{data.User_id.upi_id}</td>
                          <td>{data.amount}</td>
                          <td className="font-weight-bold text-success">
                            {data.status === "none" ? "Processing" : data.status}
                          </td>
                          <td>{dateFormat(data.createdAt).split(",")[0]}</td>
                          <td>{data.action_by ? data.action_by.Name : "N/A"}</td>
                          <td>
                            <Button className="btn btn-sm btn-primary" onClick={() => generateInvoice(data)}>
                              Generate Invoice
                            </Button>
                          </td>
                          <td>
                            <Link
                              className="btn btn-sm btn-outline-warning"
                              to={`/admin/view_transaction/${data._id}`}
                            >
                              View
                            </Link>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </div>

              <div className="mt-4">
                <ReactPaginate
                  previousLabel={"Previous"}
                  nextLabel={"Next"}
                  breakLabel={"..."}
                  pageCount={numberOfPages}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={3}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination justify-content-center"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  previousClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextClassName={"page-item"}
                  nextLinkClassName={"page-link"}
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  activeClassName={"active"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SucWithdraw;
